h5 {
  margin: 0px;
  font-size: 1.4em;
  font-weight: 700;
}

p {
  font-size: 12px;
}

.card-container {
  display: 'flex'; 
  justify-content: 'center'; 
  align-items: 'center';
  gap: 10;
  height: 200px;
  width: 200px;
}

.property-card {
  background-color: yellow;
  height: 30em;
  width: 90%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 16px;
  overflow: hidden;
  -webkit-box-shadow: 15px 15px 27px rgba(0, 0, 0, 0.5),
    -15px -15px 27px #ffffff;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.5);
  transform: skew(-1deg);
  position: relative;
  top: 0;
  transition: top ease 0.5s, box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 6px 20px 0 rgba(0, 0, 0, 0.3);
    top: -20px;
  }
}

/* ^-- The margin bottom is necessary for the drop shadow otherwise it gets clipped in certain cases. */

/* Top Half of card, image. */

.property-image {
  height: 20em;
  width: 95%;
  padding: 1em 2em;
  position: Absolute;
  top: 0px;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-size: cover;
  background-repeat: no-repeat;
}
.property-image-back {
  height: 30em;
  width: 95%;
  padding: 1em 2em;
  position: Absolute;
  top: 0px;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  background-size: cover;
  background-repeat: no-repeat;
}

/* Bottom Card Section */

.property-description {
  background-color: #fafafc;
  height: 10em;
  width: 95%;
  position: absolute;
  bottom: 0em;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align: center;
}

/* Property Cards Hover States */

// .property-card:hover .property-description {
//   height: 0em;
//   padding: 0px 1em;
// }
.property-card:hover .property-image {
  height: 30em;
}

.property-card:hover .property-social-icons {
  background-color: white;
}
